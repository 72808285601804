import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FiDribbble} from 'react-icons/fi'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://linkedin.com" target__blank><BsLinkedin/></a>
        <a href="https://github.com" target__blank><FaGithub/></a>
        <a href="https://dribbble.com" target__blank><FiDribbble/></a>
    </div>
  )
}

export default HeaderSocials