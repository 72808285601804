import React from 'react'
import './footer.css'
import {SiLinkedin} from 'react-icons/si'
import {AiFillGithub} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>SABT</a>
      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#">Contact</a></li>
      </ul>
      <div className="footer__socials">
        <a href='https://www.linkedin.com/in/sa%C3%AFdatou-baba-toherou-b99a81209/' target="_blank" rel="noopener noreferrer"><SiLinkedin/></a>
        <a href='https://github.com/Saidatou' target="_blank" rel="noopener noreferrer"><AiFillGithub/></a>
        
      </div>
      <div className="footer__copyright">
        <small>&copy; SABT: 2023. All rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer