import React from 'react'
import './contact.css'
import {MdOutlineMailOutline} from 'react-icons/md'
import {SlSocialLinkedin} from 'react-icons/sl'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0bo6ugj', 'template_uze4aaa', form.current, 'FeOrB54o-3veiF3IE')
      e.target.reset()
  };
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
          <MdOutlineMailOutline className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>sbabatoherou@gmail.com</h5>
            <a href="mailto:sbabatoherou@gmail.com" target="_blank">Send a message</a>
          </article>
          <article className="contact__option">
          <SlSocialLinkedin className='contact__option-icon'/>
            <h4>LinkedIn</h4>
            <h5>Saïdatou BABA-TOHEROU</h5>
            <a href="https://www.linkedin.com/in/sa%C3%AFdatou-baba-toherou-b99a81209/" target="_blank" rel="noopener noreferrer">Send a message</a>
           
          </article>
          <article className="contact__option">
          <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h5>+33 6 52 76 51 85</h5>
            <a href="https://api.whatsapp.com/send?phone+=33652765185" target="_blank" rel="noopener noreferrer">Send a message</a>
          </article>
        </div>
     
      {/* END OF CONTACT OPTION */}
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="name" placeholder='Your Full Name' required/>
        <input type="email" name="email" placeholder='Your Email' required/>
        <textarea name="message"  rows="10" placeholder='Your message' required></textarea>
        <button type='submit' className='btn btn-primary'>send Message</button>
      </form>
      </div>
    </section>
  )
}

export default Contact